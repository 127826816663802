import React from "react";
import * as PropTypes from "prop-types";
import { measurePropType } from "../../propTypes";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const styles = {
  chartRow: {
    display: "flex",
    flexWrap: "wrap"
  },
  heading: {
    margin: "40px 0 20px",
    fontSize: "medium",
    fontWeight: "bold"
  }
};

const ChartsChartList = ({ t, ...rest }) => (
  <div className={rest.className}>
    {rest.children.length === 10 ? (
      <>
        <div className={rest.classes.chartRow}>
          {rest.children.slice(0, rest.measure === "percentage" ? 4 : 3)}
        </div>

        <h1 className={rest.classes.heading}>
          {t("Unmet need and demand satisfied for modern methods")}
        </h1>

        <div className={rest.classes.chartRow}>
          {[5, 7, 9].map(index => rest.children[index])}
        </div>

        <h1 className={rest.classes.heading}>
          {t("Unmet need and demand satisfied for any method")}
        </h1>

        <div className={rest.classes.chartRow}>
          {[4, 6, 8].map(index => rest.children[index])}
        </div>
      </>
    ) : (
      <div className={rest.classes.chartRow}>{rest.children}</div>
    )}
  </div>
);

ChartsChartList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  measure: measurePropType.isRequired,
  className: PropTypes.string
};

export default withTranslation()(withStyles(styles)(ChartsChartList));
