import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FrIcon = props => (
  <SvgIcon viewBox="0 0 400 210.52632" {...props}>
    <g transform="scale(133.33 105.26)">
      <rect width="3" height="2" fill="#ed2939" />
      <rect width="2" height="2" fill="#fff" />
      <rect width="1" height="2" fill="#002395" />
    </g>
  </SvgIcon>
);

export default FrIcon;
