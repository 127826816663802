import React, { Component } from "react";
import authentication from "../authentication";
import * as api from "../api/api";
import i18next from "i18next";
import App from "../components/App";

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.signal = api.getSignal();

    this.state = {};
  }

  setStateSafely = (state, callback) => {
    if (!this.unmounting) {
      this.setState(state, callback);
    }
  };

  onChangeLanguage = language => {
    // noinspection JSIgnoredPromiseFromCall
    i18next.changeLanguage(language).then(() => {
      document.title = i18next.t("APP_TITLE");
    });
  };

  onLogout = () => {
    authentication.logout(error => {
      this.setStateSafely({
        error
      });

      if (!error) {
        this.props.history.push("/login");
      }
    }, this.signal.token);
  };

  componentWillUnmount() {
    this.unmounting = true;
    this.signal.cancel();
  }

  render() {
    return (
      <App onChangeLanguage={this.onChangeLanguage} onLogout={this.onLogout} />
    );
  }
}

export default AppContainer;
