import React from "react";
import * as PropTypes from "prop-types";
import { maximumYear, minimumYear } from "../../constants";
import {
  directionPropType,
  labeledValuePropType,
  maritalStatusPropType
} from "../../propTypes";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import NativeSelect from "@material-ui/core/NativeSelect";
import TextField from "@material-ui/core/TextField";
import MaritalStatusSelector from "../SentenceMaritalStatusSelector";

const styles = {
  container: {
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "baseline"
  },
  input: {
    margin: "0 0.5em"
  },
  probability: {
    width: "5rem"
  },
  year: {
    width: "4rem"
  }
};

const TargetsIndicatorLevelSpecification = props => (
  <div className={props.classes.container}>
    <span>What target of</span>

    <NativeSelect
      value={props.indicator}
      className={props.classes.input}
      onChange={e => {
        props.onSelectIndicator(e.target.value);
      }}
    >
      {props.indicators.map(indicator => (
        <option key={indicator.value} value={indicator.value}>
          {indicator.label}
        </option>
      ))}
    </NativeSelect>

    <span>among</span>

    <MaritalStatusSelector
      value={props.maritalStatus}
      className={props.classes.input}
      onChange={props.onSelectMaritalStatus}
    />

    <span>women in the year</span>

    <TextField
      type="number"
      margin="dense"
      inputProps={{
        min: minimumYear,
        max: maximumYear
      }}
      value={props.year || ""}
      className={classNames(props.classes.input, props.classes.year)}
      onChange={e => {
        props.onChangeYear(parseInt(e.target.value, 10) || undefined);
      }}
      onBlur={e => {
        props.onChangeYear(parseInt(e.target.value, 10) || undefined, true);
      }}
    />

    <span>corresponds to an attainment probability of</span>

    <TextField
      value={props.probability || ""}
      margin="dense"
      className={classNames(props.classes.input, props.classes.probability)}
      onChange={e => {
        props.onChangeProbability(e.target.value || undefined);
      }}
    />

    <span>
      where attainment probability refers to the probability of an outcome
    </span>

    <NativeSelect
      value={props.direction}
      className={props.classes.input}
      onChange={e => {
        props.onSelectDirection(e.target.value);
      }}
    >
      <option value="greaterThan">greater</option>
      <option value="lessThan">smaller</option>
    </NativeSelect>

    <span>than the target value?</span>
  </div>
);

TargetsIndicatorLevelSpecification.propTypes = {
  indicators: PropTypes.arrayOf(labeledValuePropType).isRequired,
  indicator: PropTypes.string.isRequired,
  maritalStatus: maritalStatusPropType,
  year: PropTypes.number,
  probability: PropTypes.string,
  direction: directionPropType,
  level: PropTypes.number,
  onSelectIndicator: PropTypes.func.isRequired,
  onSelectMaritalStatus: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  onChangeProbability: PropTypes.func.isRequired,
  onSelectDirection: PropTypes.func.isRequired
};

export default withStyles(styles)(TargetsIndicatorLevelSpecification);
