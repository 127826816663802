import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const EnIcon = props => (
  <SvgIcon viewBox="0 0 399.99942 210.52602" {...props}>
    <g transform="scale(.053981)">
      <rect width="7410" height="3900" fill="#b22234" />
      <path
        d="m0 450h7410m0 600h-7410m0 600h7410m0 600h-7410m0 600h7410m0 600h-7410"
        stroke="#fff"
        strokeWidth="300"
      />
      <rect width="2964" height="2100" fill="#3c3b6e" />
      <g fill="#fff">
        <g id="s18">
          <g id="s9">
            <g id="s5">
              <g id="s4">
                <path
                  id="s"
                  d="m247 90 70.534 217.08-184.66-134.16h228.25l-184.66 134.16z"
                />
                <use y="420" width="100%" height="100%" href="#s" />
                <use y="840" width="100%" height="100%" href="#s" />
                <use y="1260" width="100%" height="100%" href="#s" />
              </g>
              <use y="1680" width="100%" height="100%" href="#s" />
            </g>
            <use x="247" y="210" width="100%" height="100%" href="#s4" />
          </g>
          <use x="494" width="100%" height="100%" href="#s9" />
        </g>
        <use x="988" width="100%" height="100%" href="#s18" />
        <use x="1976" width="100%" height="100%" href="#s9" />
        <use x="2470" width="100%" height="100%" href="#s5" />
      </g>
    </g>
  </SvgIcon>
);

export default EnIcon;
