import React from "react";
import * as PropTypes from "prop-types";
import {
  emuDatumPropType,
  indicatorResultPropType,
  labeledValuePropType,
  maritalStatusPropType,
  measurePropType,
  populationDatumPropType,
  periodPropType,
  resultPropType,
  surveyDatumPropType
} from "../../propTypes";
import { Route, Switch, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Charts from "../Charts/Charts";
import EmuData from "../EmuData";
import NavigationTab from "../NavigationTab";
import PopulationData from "../PopulationData";
import ProgressContainer from "../../containers/ProgressContainer";
import Results from "../Results/Results";
import SurveyData from "../SurveyData";
import TargetsContainer from "../../containers/TargetsContainer";
import Title from "../Title";

const styles = {
  tabPage: {
    margin: 10
  },
  title: {
    marginBottom: 20
  }
};

const ViewRunMainContent = ({ match, t, ...rest }) => (
  <div>
    <div className={rest.classes.title}>
      <Title title={rest.runTitle} />
    </div>
    <NavigationTab to="/viewrun/survey">{t("Survey data")}</NavigationTab>
    <NavigationTab to="/viewrun/population">
      {t("Population data")}
    </NavigationTab>
    <NavigationTab to="/viewrun/emu">{t("Service statistics")}</NavigationTab>
    <NavigationTab to="/viewrun/results">{t("Results")}</NavigationTab>
    <NavigationTab to="/viewrun/charts">{t("Charts")}</NavigationTab>
    <NavigationTab to="/viewrun/targets">{t("Targets")}</NavigationTab>
    <NavigationTab to="/viewrun/progress">{t("Progress")}</NavigationTab>
    <div className={rest.classes.tabPage}>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect replace to={`${match.path}/survey`} />}
        />
        <Route
          path={`${match.path}/survey`}
          render={() => <SurveyData data={rest.surveyData} />}
        />
        <Route
          path={`${match.path}/population`}
          render={() => <PopulationData data={rest.populationData} />}
        />
        <Route
          path={`${match.path}/emu`}
          render={() => <EmuData data={rest.emuData} />}
        />
        <Route
          path={`${match.path}/results`}
          render={() => (
            <Results
              selectedMeasure={rest.selectedResultsMeasure}
              selectedIndicator={rest.selectedResultsIndicator}
              selectedMaritalStatus={rest.selectedResultsMaritalStatus}
              results={rest.tableResults}
              selectedExportedMeasures={rest.selectedExportedResultsMeasures}
              selectedExportedIndicators={
                rest.selectedExportedResultsIndicators
              }
              disableSelectedResultsDownload={
                rest.disableSelectedResultsDownload
              }
              disableAllResultsDownload={rest.disableAllResultsDownload}
              disableAllDataDownload={rest.disableAllDataDownload}
              onChangeMeasure={rest.onChangeResultsMeasure}
              onChangeIndicator={rest.onChangeResultsIndicator}
              onChangeMaritalStatus={rest.onChangeResultsMaritalStatus}
              onToggleExportedMeasure={rest.onToggleExportedResultsMeasure}
              onToggleExportedIndicator={rest.onToggleExportedResultsIndicator}
              onDownloadSelectedResults={rest.onDownloadSelectedResults}
              onDownloadAllResults={rest.onDownloadAllResults}
              onDownloadAllData={rest.onDownloadAllData}
            />
          )}
        />
        <Route
          path={`${match.path}/charts`}
          render={() => (
            <Charts
              period={rest.period}
              selectedMeasure={rest.selectedChartsMeasure}
              selectedMaritalStatus={rest.selectedChartsMaritalStatus}
              selectedChartsIndicators={rest.selectedChartsIndicators}
              showSurveyLegend={rest.showSurveyLegend}
              results={rest.chartResults}
              surveyData={rest.chartSurveyData}
              emuData={rest.chartEmuData}
              chartSets={rest.chartSets}
              selectedExportedChartSet={rest.selectedExportedChartSet}
              selectedExportedMeasure={rest.selectedExportedChartsMeasure}
              selectedExportedMaritalStatus={
                rest.selectedExportedChartsMaritalStatus
              }
              disableChartSetDownload={rest.disableChartSetDownload}
              disableSelectedChartsDownload={rest.disableSelectedChartsDownload}
              onChangeMeasure={rest.onChangeChartsMeasure}
              onChangeMaritalStatus={rest.onChangeChartsMaritalStatus}
              onToggleChart={rest.onToggleChart}
              onToggleSurveyLegend={rest.onToggleSurveyLegend}
              onChangeExportedChartSet={rest.onChangeExportedChartSet}
              onChangeExportedMeasure={rest.onChangeExportedChartsMeasure}
              onChangeExportedMaritalStatus={
                rest.onChangeExportedChartsMaritalStatus
              }
              onDownloadChartSet={rest.onDownloadChartSet}
              onDownloadSelectedCharts={rest.onDownloadSelectedCharts}
            />
          )}
        />
        <Route
          path={`${match.path}/targets`}
          render={() => (
            <TargetsContainer
              runId={rest.selectedRunId}
              countryNumericCode={rest.selectedCountryNumericCode}
              region={rest.selectedRegion}
            />
          )}
        />
        <Route
          path={`${match.path}/progress`}
          render={() => (
            <ProgressContainer
              runId={rest.selectedRunId}
              countryNumericCode={rest.selectedCountryNumericCode}
              region={rest.selectedRegion}
            />
          )}
        />
      </Switch>
    </div>
  </div>
);

ViewRunMainContent.propTypes = {
  selectedRunId: PropTypes.string,
  selectedCountryNumericCode: PropTypes.number,
  selectedRegion: PropTypes.string,
  period: periodPropType,
  runTitle: PropTypes.string,
  surveyData: PropTypes.arrayOf(surveyDatumPropType),
  populationData: PropTypes.arrayOf(populationDatumPropType),
  emuData: PropTypes.arrayOf(emuDatumPropType),
  selectedResultsMeasure: PropTypes.oneOf(["percentage", "count"]).isRequired,
  selectedResultsIndicator: PropTypes.string.isRequired,
  selectedResultsMaritalStatus: PropTypes.string.isRequired,
  tableResults: PropTypes.arrayOf(indicatorResultPropType),
  selectedExportedResultsMeasures: PropTypes.arrayOf(measurePropType),
  selectedExportedResultsIndicators: PropTypes.arrayOf(PropTypes.string),
  disableSelectedResultsDownload: PropTypes.bool.isRequired,
  disableAllResultsDownload: PropTypes.bool.isRequired,
  disableAllDataDownload: PropTypes.bool.isRequired,
  selectedChartsMeasure: measurePropType.isRequired,
  selectedChartsMaritalStatus: maritalStatusPropType,
  selectedChartsIndicators: PropTypes.arrayOf(PropTypes.string),
  showSurveyLegend: PropTypes.bool,
  chartResults: resultPropType,
  chartSurveyData: PropTypes.arrayOf(surveyDatumPropType),
  chartEmuData: PropTypes.arrayOf(emuDatumPropType),
  chartSets: PropTypes.arrayOf(labeledValuePropType),
  selectedExportedChartSet: PropTypes.string.isRequired,
  selectedExportedChartsMeasure: measurePropType.isRequired,
  selectedExportedChartsMaritalStatus: maritalStatusPropType,
  disableChartSetDownload: PropTypes.bool.isRequired,
  disableSelectedChartsDownload: PropTypes.bool.isRequired,
  onChangeResultsMeasure: PropTypes.func.isRequired,
  onChangeResultsIndicator: PropTypes.func.isRequired,
  onChangeResultsMaritalStatus: PropTypes.func.isRequired,
  onToggleExportedResultsMeasure: PropTypes.func.isRequired,
  onToggleExportedResultsIndicator: PropTypes.func.isRequired,
  onDownloadSelectedResults: PropTypes.func.isRequired,
  onDownloadAllResults: PropTypes.func.isRequired,
  onDownloadAllData: PropTypes.func.isRequired,
  onChangeChartsMeasure: PropTypes.func.isRequired,
  onChangeChartsMaritalStatus: PropTypes.func.isRequired,
  onToggleChart: PropTypes.func.isRequired,
  onToggleSurveyLegend: PropTypes.func.isRequired,
  onChangeExportedChartSet: PropTypes.func.isRequired,
  onChangeExportedChartsMeasure: PropTypes.func.isRequired,
  onChangeExportedChartsMaritalStatus: PropTypes.func.isRequired,
  onDownloadChartSet: PropTypes.func.isRequired,
  onDownloadSelectedCharts: PropTypes.func.isRequired
};

ViewRunMainContent.defaultProps = {
  surveyData: [],
  populationData: [],
  emuData: [],
  tableResults: [],
  selectedChartsIndicators: [],
  showSurveyLegend: false,
  chartSurveyData: [],
  chartEmuData: [],
  chartSets: [],
  selectedExportedResultsMeasures: [],
  selectedExportedResultsIndicators: []
};

export default withTranslation()(withStyles(styles)(ViewRunMainContent));
