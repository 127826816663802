import React from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  button: {
    width: "100%"
  },
  container: {
    position: "relative"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
};

const ProgressButton = props => (
  <div className={classNames(props.classes.container, props.className)}>
    <Button
      variant={props.variant}
      color={props.color}
      disabled={props.disabled || props.loading}
      className={classNames(props.classes.button, props.buttonClassName)}
      onClick={props.onClick}
    >
      {props.children}
    </Button>

    {props.loading && (
      <CircularProgress size={24} className={props.classes.progress} />
    )}
  </div>
);

ProgressButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

ProgressButton.defaultProps = {
  variant: "contained",
  color: "primary",
  disabled: false,
  loading: false
};

export default withStyles(styles)(ProgressButton);
