import React from "react";
import * as PropTypes from "prop-types";
import {
  deltaPropType,
  directionPropType,
  maritalStatusPropType
} from "../../propTypes";
import { maximumYear, minimumYear } from "../../constants";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import NativeSelect from "@material-ui/core/NativeSelect";
import TextField from "@material-ui/core/TextField";
import MaritalStatusSelector from "../SentenceMaritalStatusSelector";

const styles = {
  container: {
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "baseline"
  },
  input: {
    margin: "0 0.5em"
  },
  level: {
    width: "8rem"
  },
  year: {
    width: "4rem"
  }
};

const TargetsPopulationProbabilitySpecification = props => (
  <div className={props.classes.container}>
    <span>What is the probability that the</span>

    <NativeSelect
      value={props.delta}
      className={props.classes.input}
      onChange={e => {
        props.onSelectDelta(e.target.value);
      }}
    >
      <option value="total">total</option>
      <option value="additional">additional</option>
    </NativeSelect>

    {props.delta === "additional" && (
      <>
        <span>(relative to the year</span>

        <TextField
          type="number"
          margin="dense"
          inputProps={{
            min: minimumYear,
            max: maximumYear
          }}
          value={props.relativeToYear || ""}
          className={classNames(props.classes.input, props.classes.year)}
          onChange={e => {
            props.onChangeRelativeToYear(
              parseInt(e.target.value, 10) || undefined
            );
          }}
          onBlur={e => {
            props.onChangeRelativeToYear(
              parseInt(e.target.value, 10) || undefined,
              true
            );
          }}
        />
      </>
    )}

    <span>{`${props.delta === "additional" ? ") " : ""}number of`}</span>

    <MaritalStatusSelector
      value={props.maritalStatus}
      className={props.classes.input}
      onChange={props.onSelectMaritalStatus}
    />

    <span>women</span>

    <NativeSelect
      value={props.contraceptiveMethod}
      className={props.classes.input}
      onChange={e => {
        props.onSelectContraceptiveMethod(e.target.value);
      }}
    >
      {props.contraceptiveMethods.map(indicator => (
        <option key={indicator.value} value={indicator.value}>
          {indicator.label}
        </option>
      ))}
    </NativeSelect>

    <span>in the year</span>

    <TextField
      type="number"
      margin="dense"
      inputProps={{
        min: minimumYear,
        max: maximumYear
      }}
      value={props.year || ""}
      className={classNames(props.classes.input, props.classes.year)}
      onChange={e => {
        props.onChangeYear(parseInt(e.target.value, 10) || undefined);
      }}
      onBlur={e => {
        props.onChangeYear(parseInt(e.target.value, 10) || undefined, true);
      }}
    />

    <span>is</span>

    <NativeSelect
      value={props.direction}
      className={props.classes.input}
      onChange={e => {
        props.onSelectrDirection(e.target.value);
      }}
    >
      <option value="greaterThan">above</option>
      <option value="lessThan">below</option>
    </NativeSelect>

    <TextField
      type="number"
      margin="dense"
      inputProps={{
        min: 0
      }}
      value={props.level || ""}
      className={classNames(props.classes.input, props.classes.level)}
      onChange={e => {
        props.onChangeLevel(e.target.value || undefined);
      }}
    />

    <span>?</span>
  </div>
);

TargetsPopulationProbabilitySpecification.propTypes = {
  contraceptiveMethods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  delta: deltaPropType,
  relativeToYear: PropTypes.number,
  maritalStatus: maritalStatusPropType,
  contraceptiveMethod: PropTypes.string.isRequired,
  year: PropTypes.number,
  level: PropTypes.number,
  direction: directionPropType,
  probability: PropTypes.number,
  onSelectDelta: PropTypes.func.isRequired,
  onChangeRelativeToYear: PropTypes.func.isRequired,
  onSelectMaritalStatus: PropTypes.func.isRequired,
  onSelectContraceptiveMethod: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  onSelectDirection: PropTypes.func.isRequired,
  onChangeLevel: PropTypes.func.isRequired
};

export default withStyles(styles)(TargetsPopulationProbabilitySpecification);
