import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en";
import fr from "./translations/fr";

const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export const translateGridText = (key, defaultValue) =>
  i18next.t(`ag-grid.${key}`, defaultValue);

export default i18n;
