import { createGuid, getCurrentYear, groupBy } from "./utilities";

const toNullable = value => (value === "None" ? null : value);

const normalizeDivisions = divisions =>
  divisions.reduce((result, division) => {
    const { numericCode, ...rest } = division;

    result[numericCode] = rest;

    return result;
  }, {});

export const normalizeDatabases = databases =>
  databases.reduce((result, database) => {
    const { id, ...rest } = database;

    let regionCodes = database.regionCodes || database.regions;

    regionCodes = regionCodes && regionCodes.filter(regionCode => regionCode);

    result[id] = {
      ...rest,
      divisions: normalizeDivisions(database.divisions),
      regionCodes: regionCodes
    };

    return result;
  }, {});

export const normalizeSurveyData = surveyData =>
  surveyData.map(datum => ({
    ...datum,
    ageGroupBias: toNullable(datum.ageGroupBias),
    modernMethodBias: toNullable(datum.modernMethodBias),
    ratioModernAny: datum.ratioModernAny
  }));

export const normalizePopulationData = populationData => populationData;

export const normalizeEmuData = emuData =>
  emuData.map(datum => ({
    ...datum,
    startYear: datum.year,
    endYear: datum.year
  }));

export const normalizeRuns = runs =>
  runs.reduce((result, run) => {
    const { id, ...rest } = run;

    result[id] = {
      ...rest,
      startedAt: new Date(rest.startedAt),
      createdAt: new Date(rest.createdAt),
      completed: rest.completed || rest.failed
    };

    return result;
  }, {});

export const normalizeResults = results => groupBy(results, "indicator");

export const normalizeChartSets = data => data;

export const filterSurveyData = (surveyData, firstYear, lastYear) =>
  surveyData &&
  surveyData.filter(
    datum => datum.startDate >= firstYear && datum.startDate <= lastYear
  );

export const filterPopulationData = (populationData, firstYear, lastYear) =>
  populationData &&
  populationData.filter(
    datum => datum.midYear >= firstYear && datum.midYear <= lastYear
  );

export const getPeriod = results => {
  const years = results.map(datum => datum.year);

  return {
    firstYear: Math.min(...years),
    lastYear: Math.max(...years)
  };
};

export const filterResults = (results, firstYear, lastYear) => {
  if (!results) {
    return;
  }

  return Object.keys(results).reduce((result, indicator) => {
    result[indicator] = results[indicator].filter(
      datum => datum.year >= firstYear && datum.year <= lastYear
    );

    return result;
  }, {});
};

export const getScaledResults = (measure, results) => {
  if (measure === "percentage") {
    return results;
  }

  return results.map(datum => ({
    ...datum,
    percentile2pt5: datum.percentile2pt5 / 1000,
    percentile10: datum.percentile10 / 1000,
    median: datum.median / 1000,
    percentile90: datum.percentile90 / 1000,
    percentile97pt5: datum.percentile97pt5 / 1000,
    comparisonPercentile2pt5: datum.comparisonPercentile2pt5 / 1000,
    comparisonPercentile10: datum.comparisonPercentile10 / 1000,
    comparisonMedian: datum.comparisonMedian / 1000,
    comparisonPercentile90: datum.comparisonPercentile90 / 1000,
    comparisonPercentile97pt5: datum.comparisonPercentile97pt5 / 1000
  }));
};

export const convertMaritalStatusToIsInUnion = maritalStatus => {
  switch (maritalStatus) {
    case "married":
      return true;

    case "unmarried":
      return false;

    default:
      return null;
  }
};

export const createDefaultSurveyDatum = () => {
  const currentYear = getCurrentYear();

  return {
    id: createGuid(),
    include: true,
    startDate: currentYear - 1,
    endDate: currentYear,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseAny: 0,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    hasGeographicalRegionBias: false,
    hasNonPregnantAndOtherPositiveBiases: false,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    hasAbsenceOfProbingQuestionsBias: false
  };
};

export const createDefaultPopulationDatum = () => ({
  id: createGuid(),
  isInUnion: true,
  ageRange: "15-49",
  midYear: getCurrentYear(),
  population: 0
});

export const createDefaultEmuDatum = () => {
  const currentYear = getCurrentYear();

  return {
    id: createGuid(),
    startYear: currentYear,
    endYear: currentYear,
    emu: 0,
    serviceStatisticType: "clients"
  };
};

export const synchonizeContraceptivePrevalence = (item, updatedField) => {
  let {
    contraceptiveUseModern,
    contraceptiveUseTraditional,
    contraceptiveUseAny,
    ...rest
  } = item;

  if (updatedField === "contraceptiveUseModern") {
    contraceptiveUseTraditional = contraceptiveUseTraditional || 0;

    if (contraceptiveUseModern + contraceptiveUseTraditional > 1) {
      contraceptiveUseTraditional = 1 - contraceptiveUseModern;
    }

    contraceptiveUseAny = contraceptiveUseModern + contraceptiveUseTraditional;
  } else if (updatedField === "contraceptiveUseTraditional") {
    contraceptiveUseModern = contraceptiveUseModern || 0;

    if (contraceptiveUseModern + contraceptiveUseTraditional > 1) {
      contraceptiveUseModern = 1 - contraceptiveUseTraditional;
    }

    contraceptiveUseAny = contraceptiveUseModern + contraceptiveUseTraditional;
  } else if (updatedField === "contraceptiveUseAny") {
    contraceptiveUseModern = undefined;
    contraceptiveUseTraditional = undefined;
  }

  return {
    ...rest,
    contraceptiveUseModern,
    contraceptiveUseTraditional,
    contraceptiveUseAny
  };
};
