export const developmentMode = false && process.env.NODE_ENV === "development";

export const chartSets = [
  {
    value: "complete",
    label: "Complete",
  },
  {
    value: "cp_any",
    label: "CP (any)",
  },
  {
    value: "cp_modern",
    label: "CP (modern)",
  },
  {
    value: "cp_traditional",
    label: "CP (traditional)",
  },
  {
    value: "ratio_modern_vs_any_method",
    label: "Ratio (modern / any method)",
  },
  {
    value: "unmet_need_for_fp",
    label: "Unmet need for FP",
  },
  {
    value: "unmet_need_modern",
    label: "Unmet need for modern methods",
  },
  {
    value: "total_demand",
    label: "Total demand",
  },
  {
    value: "demand_satisfied",
    label: "Demand satisfied",
  },
  {
    value: "demand_satisfied_by_modern_methods",
    label: "Demand satisfied by modern methods",
  },
  {
    value: "fp_2020",
    label: "FP 2020",
  },
];

export const contraceptiveMethods = [
  {
    value: "contraceptiveUseAny",
    label: "using any contraception",
  },
  {
    value: "contraceptiveUseModern",
    label: "using modern contraception",
  },
  {
    value: "contraceptiveUseTraditional",
    label: "using traditional contraception",
  },
  {
    value: "unmetNeedAny",
    label: "with unmet need for FP",
  },
  {
    value: "unmetNeedModern",
    label: "with unmet need for modern methods",
  },
  {
    value: "demand",
    label: "with demand for FP",
  },
];

export const defaultFirstYear = 1970;

export const indicators = [
  {
    value: "contraceptiveUseAny",
    label: "Contraceptive prevalence (any)",
    description:
      "The proportion of women who are currently using, or whose sexual partner is currently using, at least one method of contraception",
    chart: true,
  },
  {
    value: "contraceptiveUseModern",
    label: "Contraceptive prevalence (modern)",
    description:
      "The proportion of women who are currently using, or whose sexual partner is currently using, at least one modern method of contraception",
    chart: true,
  },
  {
    value: "contraceptiveUseTraditional",
    label: "Contraceptive prevalence (traditional)",
    description:
      "The proportion of women who are currently using, or whose sexual partner is currently using, at least one traditional method of contraception (and neither is using a modern method)",
    chart: true,
  },
  {
    value: "ratioModernAny",
    label: "Modern/Any method (ratio)",
    description:
      "The ratio of contraceptive prevalence (modern) over contraceptive prevalence (any), the percent of contraceptive use that is modern",
    chart: true,
  },
  {
    value: "unmetNeedAny",
    label: "Unmet need for family planning",
    description:
      "The proportion of women who want to stop or delay childbearing but are not using any method of contraception",
    chart: true,
  },
  {
    value: "unmetNeedModern",
    label: "Unmet need for family planning (modern)",
    description:
      "The proportion of women who want to stop or delay childbearing but are not using a modern method of contraception",
    chart: true,
  },
  {
    value: "demand",
    label: "Total demand for family planning",
    description:
      "The proportion of women who are either using any method of contraception (or whose sexual partner is currently using) or who have an unmet need for family planning",
    chart: true,
  },
  {
    value: "demandModern",
    label: "Demand for modern family planning",
    description:
      "The proportion of women who are either using any method of contraception (or whose sexual partner is currently using) or who have an unmet need for family planning",
    chart: true,
  },
  {
    value: "demandSatisfied",
    label: "Demand for family planning satisfied by any method",
    description:
      "The proportion of women who are currently using, or whose sexual partner is currently using, any contraceptive method out of the women of reproductive age who have a demand for family planning, either by using any method of contraception or by having an unmet need for family planning",
    chart: true,
  },
  {
    value: "demandSatisfiedModern",
    label: "Demand for family planning satisfied by modern methods",
    description:
      "The proportion of women who are currently using, or whose sexual partner is currently using, at least one modern contraceptive method out of women of reproductive age who have a demand for family planning, either by using any method of contraception or by having an unmet need for family planning",
    chart: true,
  },
  {
    value: "noNeed",
    label: "No need for contraception",
    description: "",
    chart: false,
  },
];

export const maximumYear = 2030;

export const minimumYear = 1970;
