import React from "react";
import * as PropTypes from "prop-types";
import { managedLabeledValuePropType } from "../../propTypes";
import { Route, Switch, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Databases from "./ManageDataDatabases";
import Loading from "../Loading";
import NavigationTab from "../NavigationTab";
import Runs from "./ManageDataRuns";

const styles = {
  tabPage: {
    margin: 10
  }
};

const ManageData = ({ match, t, ...rest }) => (
  <Loading
    loading={rest.loadingData}
    message={t("Loading databases and runs...")}
  >
    <p>
      {t(
        "Cleaning up data you no longer need allows you to focus on new analyses."
      )}
    </p>

    <NavigationTab to="/managedata/databases">{t("Databases")}</NavigationTab>
    <NavigationTab to="/managedata/runs">{t("Runs")}</NavigationTab>

    <div className={rest.classes.tabPage}>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect replace to={`${match.path}/databases`} />}
        />
        <Route
          path={`${match.path}/databases`}
          render={() => (
            <Databases
              databases={rest.databases}
              selectedDatabaseIds={rest.selectedDatabaseIds}
              onToggleDatabase={rest.onToggleDatabase}
              onDownloadDatabases={rest.onDownloadDatabases}
              onDeleteDatabases={rest.onDeleteDatabases}
            />
          )}
        />
        <Route
          path={`${match.path}/runs`}
          render={() => (
            <Runs
              runs={rest.runs}
              selectedRunIds={rest.selectedRunIds}
              onToggleRun={rest.onToggleRun}
              onDeleteRuns={rest.onDeleteRuns}
            />
          )}
        />
      </Switch>
    </div>
  </Loading>
);

ManageData.propTypes = {
  loadingData: PropTypes.bool,
  databases: PropTypes.arrayOf(managedLabeledValuePropType),
  runs: PropTypes.arrayOf(managedLabeledValuePropType),
  selectedDatabaseIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRunIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleDatabase: PropTypes.func.isRequired,
  onDeleteDatabases: PropTypes.func.isRequired,
  onDownloadDatabases: PropTypes.func.isRequired,
  onToggleRun: PropTypes.func.isRequired,
  onDeleteRuns: PropTypes.func.isRequired
};

ManageData.defaultProps = {
  loadingData: false,
  databases: [],
  runs: []
};

export default withTranslation()(withStyles(styles)(ManageData));
