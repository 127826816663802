import React from "react";
import * as PropTypes from "prop-types";
import {
  labeledValuePropType,
  maritalStatusPropType,
  measurePropType
} from "../../propTypes";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import ChartSetSelector from "./ChartSetSelector";
import MaritalStatusSelector from "./ChartsMaritalStatusSelector";
import MeasureSelector from "../MeasureSelector";

const styles = {
  button: {
    marginRight: 20
  },
  buttonGroup: {
    marginTop: 20
  },
  leftSelector: {
    marginRight: 30
  },
  selectors: {
    display: "flex"
  },
  title: {
    marginBottom: 10
  }
};

const ChartsExporter = ({ t, ...rest }) => (
  <div>
    <Typography variant="h5" className={rest.classes.title}>
      {t("Export chart sets")}
    </Typography>

    <div className={rest.classes.selectors}>
      <ChartSetSelector
        chartSets={rest.chartSets}
        value={rest.selectedChartSet}
        className={rest.classes.leftSelector}
        onChange={rest.onChangeChartSet}
      />
      <MeasureSelector
        value={rest.selectedMeasure}
        className={rest.classes.leftSelector}
        onChange={rest.onChangeMeasure}
      />
      <MaritalStatusSelector
        value={rest.selectedMaritalStatus}
        onChange={rest.onChangeMaritalStatus}
      />
    </div>

    <div className={rest.classes.buttonGroup}>
      <Button
        variant="contained"
        color="primary"
        disabled={rest.disableChartSetDownload}
        className={rest.classes.button}
        onClick={rest.onDownloadChartSet}
      >
        {t("Download chart set")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={rest.disableSelectedChartsDownload}
        className={rest.classes.button}
        onClick={rest.onDownloadSelectedCharts}
      >
        {t("Download displayed charts")}
      </Button>
    </div>
  </div>
);

ChartsExporter.propTypes = {
  chartSets: PropTypes.arrayOf(labeledValuePropType),
  selectedChartSet: PropTypes.string.isRequired,
  selectedMeasure: measurePropType.isRequired,
  selectedMaritalStatus: maritalStatusPropType,
  disableChartSetDownload: PropTypes.bool.isRequired,
  disableSelectedChartsDownload: PropTypes.bool.isRequired,
  onChangeChartSet: PropTypes.func.isRequired,
  onChangeMeasure: PropTypes.func.isRequired,
  onChangeMaritalStatus: PropTypes.func.isRequired,
  onDownloadChartSet: PropTypes.func.isRequired,
  onDownloadSelectedCharts: PropTypes.func.isRequired
};

export default withTranslation()(withStyles(styles)(ChartsExporter));
