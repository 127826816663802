import React from "react";
import * as PropTypes from "prop-types";
import { maritalStatusPropType } from "../../propTypes";
import { maximumYear, minimumYear } from "../../constants";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import NativeSelect from "@material-ui/core/NativeSelect";
import TextField from "@material-ui/core/TextField";
import MaritalStatusSelector from "../SentenceMaritalStatusSelector";

const styles = {
  container: {
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "baseline"
  },
  input: {
    margin: "0 0.5em"
  },
  year: {
    width: "4rem"
  }
};

const ProgressContraceptionMethodSpecification = props => (
  <div>
    <span>The change in the number of</span>

    <MaritalStatusSelector
      value={props.maritalStatus}
      className={props.classes.input}
      onChange={props.onSelectMaritalStatus}
    />

    <span>women</span>

    <NativeSelect
      value={props.contraceptiveMethod}
      className={props.classes.input}
      onChange={e => {
        props.onSelectContraceptiveMethod(e.target.value);
      }}
    >
      {props.contraceptiveMethods.map(contraceptiveMethod => (
        <option
          key={contraceptiveMethod.value}
          value={contraceptiveMethod.value}
        >
          {contraceptiveMethod.label}
        </option>
      ))}
    </NativeSelect>

    <span>from the year</span>

    <TextField
      type="number"
      margin="dense"
      inputProps={{
        min: minimumYear,
        max: maximumYear
      }}
      value={(props.period && props.period.firstYear) || ""}
      className={classNames(props.classes.input, props.classes.year)}
      onChange={e => {
        props.onChangeFirstYear(parseInt(e.target.value, 10) || undefined);
      }}
      onBlur={e => {
        props.onChangeFirstYear(
          parseInt(e.target.value, 10) || undefined,
          true
        );
      }}
    />

    <span>to the year</span>

    <TextField
      type="number"
      margin="dense"
      inputProps={{
        min: minimumYear,
        max: maximumYear
      }}
      value={(props.period && props.period.lastYear) || ""}
      className={classNames(props.classes.input, props.classes.year)}
      onChange={e => {
        props.onChangeLastYear(parseInt(e.target.value, 10) || undefined);
      }}
      onBlur={e => {
        props.onChangeLastYear(parseInt(e.target.value, 10) || undefined, true);
      }}
    />

    <span>is...</span>
  </div>
);

ProgressContraceptionMethodSpecification.propTypes = {
  contraceptiveMethods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  maritalStatus: maritalStatusPropType,
  contraceptiveMethod: PropTypes.string.isRequired,
  onSelectMaritalStatus: PropTypes.func.isRequired,
  onSelectContraceptiveMethod: PropTypes.func.isRequired,
  onChangeFirstYear: PropTypes.func.isRequired,
  onChangeLastYear: PropTypes.func.isRequired
};

export default withStyles(styles)(ProgressContraceptionMethodSpecification);
