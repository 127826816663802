import React, { Component } from "react";
import { createSlug } from "../utilities";
import { normalizeRuns } from "../data";
import * as api from "../api/api";
import fileDownload from "js-file-download";
import { withErrorBoundary } from "../errors";
import { withTranslation } from "react-i18next";
import RunHistory from "../components/RunHistory";

const createInitialState = () => ({});

const getNewlyCompletedRunIds = (previousRuns, currentRuns) => {
  if (!previousRuns) {
    return [];
  }

  const previouslyCompletedRunIds = new Set(
    (previousRuns || []).filter(run => run.completed).map(run => run.id)
  );

  const currentlyCompletedRunIds = (currentRuns || [])
    .filter(run => run.completed)
    .map(run => run.id);

  return currentlyCompletedRunIds.filter(
    runId => !previouslyCompletedRunIds.has(runId)
  );
};

class RunHistoryContainer extends Component {
  constructor(props) {
    super(props);

    this.signal = api.getSignal();

    this.state = createInitialState();
  }

  setStateSafely = (state, callback) => {
    if (!this.unmounting) {
      this.setState(state, callback);
    }
  };

  fetchRuns = refreshing => {
    this.setStateSafely({
      loadingData: !refreshing
    });

    api
      .fetchRuns(true, true, this.signal.token)
      .then(response => {
        if (!response) {
          this.runsHash = undefined;

          return;
        }

        const runs = Object.entries(normalizeRuns(response.data)).map(
          ([id, run]) => ({
            id: id,
            name: run.name,
            startedAt: run.startedAt,
            completed: run.completed,
            failed: run.failed
          })
        );

        const newlyCompletedRunIds = getNewlyCompletedRunIds(
          this.state.runs,
          runs
        );

        const notification = newlyCompletedRunIds.length && {
          notificationVariant: "success",
          notificationOpen: true,
          notificationMessage: this.props.t(
            "{{runCount}} run(s) recently completed",
            {
              runCount: newlyCompletedRunIds.length
            }
          )
        };

        this.setStateSafely({
          runs: Object.entries(normalizeRuns(response.data)).map(
            ([id, run]) => ({
              id: id,
              name: run.name,
              startedAt: run.startedAt,
              completed: run.completed,
              failed: run.failed
            })
          ),
          ...(notification || {})
        });
      })
      .finally(() => {
        this.setStateSafely({
          loadingData: false
        });

        this.refreshTimer = window.setTimeout(
          () => this.fetchRuns(true),
          60000
        );
      });
  };

  onDownloadRunLog = runId => {
    api.fetchRunLog(runId, this.signal.token).then(response => {
      if (!response) {
        return;
      }

      const run = this.state.runs.find(run => run.id === runId);

      if (run) {
        fileDownload(response.data, createSlug(run.name, "run.log"));
      }
    });
  };

  onCloseNotification = () => {
    this.setStateSafely({
      notificationOpen: false
    });
  };

  componentDidMount() {
    this.fetchRuns(false);
  }

  componentWillUnmount() {
    this.unmounting = true;

    if (this.refreshTimer) {
      window.clearTimeout(this.refreshTimer);
    }

    this.signal.cancel();
  }

  render() {
    return (
      <RunHistory
        loadingData={this.state.loadingData}
        runs={this.state.runs}
        notificationVariant={this.state.notificationVariant}
        notificationOpen={this.state.notificationOpen}
        notificationMessage={this.state.notificationMessage}
        onDownloadRunLog={this.onDownloadRunLog}
        onCloseNotification={this.onCloseNotification}
      />
    );
  }
}

export default withTranslation()(withErrorBoundary(RunHistoryContainer));
