import React from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  loading: {
    display: "flex",
    alignItems: "center"
  },
  message: {
    marginLeft: theme.spacing.unit * 2
  }
});

const Loading = props => (
  <div>
    {!props.loading ? (
      props.children
    ) : (
      <div className={props.classes.loading}>
        <CircularProgress size={20} />
        <div className={props.classes.message}>{props.message}</div>
      </div>
    )}
  </div>
);

Loading.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string
};

Loading.defaultProps = {
  loading: false,
  message: "Loading data..."
};

export default withStyles(styles)(Loading);
