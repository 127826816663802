import axios from "axios";
import MockApiAdapter from "./mockApiAdapter";
import { developmentMode, maximumYear, minimumYear } from "../constants";
import i18next from "i18next";

const createSession = (username, token) => {
  localStorage.setItem("session", JSON.stringify({ username, token }));
};

const getSession = () => {
  const data = localStorage.getItem("session");

  return data ? JSON.parse(data) : { username: undefined, token: undefined };
};

const deleteSession = () => {
  localStorage.removeItem("session");
};

const createHeaders = () => ({
  "FPET-Auth": `token ${getSession().token}`,
  "FPET-Language": i18next.language
});

const webService = axios.create({
  baseURL: process.env.REACT_APP_WEB_SERVICE_URL
});

webService.interceptors.response.use(undefined, error => {
  if (error.response.status === 401) {
    deleteSession();

    window.location.href = "/";

    return;
  }

  return Promise.reject(error);
});

if (developmentMode || window.location.search.includes("enable-api-mocking")) {
  new MockApiAdapter(webService);
}

const fetch = (url, cancellationToken, config = {}) =>
  webService
    .get(url, {
      headers: createHeaders(),
      cancellationToken,
      ...config
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        console.log(error);

        throw error;
      }
    });

const createUrl = (path, query, appendSessionToken) => {
  let url = `${process.env.REACT_APP_WEB_SERVICE_URL}/${path}`;

  if (query) {
    url = `${url}?${query}`;
  }

  if (appendSessionToken) {
    url = `${url}${!query ? "?" : "&"}token=${getSession().token}`;
  }

  return url;
};

export const getUsername = () => getSession().username;

export const createRunLogDownloadUrl = runId =>
  createUrl(`/api/v1/logs/${runId}`, undefined);

const createDatabasesDownloadUrl = databaseIds =>
  databaseIds && databaseIds.length
    ? createUrl(
        "/api/v1/csv/databases/",
        databaseIds.map(databaseId => `id=${databaseId}`).join("&")
      )
    : undefined;

export const getSignal = () => axios.CancelToken.source();

export const fetchSystemStatus = cancellationToken =>
  fetch("/api/v1/systeminfo", cancellationToken);

export const login = (username, password, cancellationToken) =>
  webService
    .post(`/api/v1/session/${username}?password=${password}`, {
      cancellationToken
    })
    .then(response => {
      createSession(username, response.data.token);

      return response;
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });

export const logout = (username, cancellationToken) =>
  webService
    .delete(`/api/v1/session/${username}`, {
      headers: createHeaders(),
      cancellationToken
    })
    .then(response => {
      deleteSession();

      return response;
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });

export const fetchDatabases = cancellationToken =>
  fetch("/api/v1/databases/", cancellationToken);

export const uploadDatabase = (data, type, onProgress, cancellationToken) =>
  webService
    .post(`/api/v1/databases/files/${type}/`, data, {
      headers: createHeaders(),
      cancellationToken,
      onUploadProgress: onProgress
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });

export const startRun = (
  runName,
  surveyDatabaseId,
  populationDatabaseId,
  emuDatabaseId,
  countryNumericCode,
  region,
  period,
  cancellationToken
) =>
  webService.post(
    "/api/v1/runs/",
    {
      name: runName,
      surveyDatabaseId,
      populationDatabaseId,
      emuDatabaseId,
      countryNumericCode: !region ? countryNumericCode : undefined,
      regionCode: region,
      period: {
        firstYear: minimumYear,
        lastYear: maximumYear
      }
    },
    {
      headers: createHeaders(),
      cancellationToken
    }
  );

export const fetchRuns = (
  includePendingRuns,
  includeFailedRuns,
  cancellationToken
) => {
  const url = createUrl(
    "/api/v1/runs/",
    `includePending=${includePendingRuns}&includeFailed=${includeFailedRuns}`
  );

  return fetch(url, cancellationToken);
};

export const fetchSurveyDatabase = (
  databaseId,
  divisionNumericCodeOrRegion,
  runId,
  cancellationToken
) => {
  let url = `/api/v1/surveys/${databaseId}/${divisionNumericCodeOrRegion}`;

  if (runId) {
    url = `${url}?runId=${runId}`;
  }

  return fetch(url, cancellationToken);
};

export const fetchPopulationDatabase = (
  databaseId,
  divisionNumericCodeOrRegion,
  cancellationToken
) =>
  fetch(
    `/api/v1/populations/${databaseId}/${divisionNumericCodeOrRegion}`,
    cancellationToken
  );

export const fetchEmuDatabase = (
  databaseId,
  divisionNumericCodeOrRegion,
  cancellationToken
) =>
  fetch(
    `/api/v1/emu/${databaseId}/${divisionNumericCodeOrRegion}`,
    cancellationToken
  );

export const fetchResults = (
  runId,
  comparisonRunId,
  divisionNumericCodeOrRegion,
  cancellationToken
) => {
  let url = `/api/v1/results/${runId}/${divisionNumericCodeOrRegion}`;

  if (comparisonRunId) {
    url = `${url}?comparisonRun=${comparisonRunId}`;
  }

  return fetch(url, cancellationToken);
};

export const createResultsDownloadUrl = (
  runId,
  comparisonRunId,
  countryNumericCodeOrRegion,
  measures,
  indicators
) => {
  if (
    !countryNumericCodeOrRegion ||
    !indicators ||
    !indicators.length ||
    !measures ||
    !measures.length
  ) {
    return;
  }

  const path = `/api/v1/csv/results/${runId}/${countryNumericCodeOrRegion}/`;

  let query = measures
    .map(measure => `measure=${measure}`)
    .concat(indicators.map(indicator => `indicator=${indicator}`))
    .join("&");

  if (comparisonRunId) {
    query = `${query}&comparisonRun=${comparisonRunId}`;
  }

  return createUrl(path, query);
};

export const downloadResults = (
  runId,
  comparisonRunId,
  countryNumericCodeOrRegion,
  measures,
  indicators,
  cancellationToken
) => {
  const url = createResultsDownloadUrl(
    runId,
    comparisonRunId,
    countryNumericCodeOrRegion,
    measures,
    indicators
  );

  return fetch(url, cancellationToken);
};

export const downloadData = (
  runId,
  countryNumericCodeOrRegion,
  cancellationToken
) => {
  const url = `/api/v1/csv/dataset/${runId}/${countryNumericCodeOrRegion}/`;

  return fetch(url, cancellationToken, { responseType: "blob" });
};

export const fetchChartSets = cancellationToken =>
  fetch("/api/v1/chartsets", cancellationToken);

export const createChartSetDownloadUrl = (
  runId,
  comparisonRunId,
  countryNumericCodeOrRegion,
  chartSet,
  maritalStatus,
  measure
) =>
  countryNumericCodeOrRegion &&
  createUrl(
    `/api/v1/chartsets/${chartSet}/${runId}/${countryNumericCodeOrRegion}/${maritalStatus}/${measure}`,
    undefined
  );

export const downloadChartSet = (
  runId,
  comparisonRunId,
  countryNumericCodeOrRegion,
  chartSet,
  maritalStatus,
  measure,
  cancellationToken
) => {
  const url = createChartSetDownloadUrl(
    runId,
    comparisonRunId,
    countryNumericCodeOrRegion,
    chartSet,
    maritalStatus,
    measure
  );

  return fetch(url, cancellationToken, { responseType: "arraybuffer" });
};

export const createChartsDownloadUrl = (
  runId,
  comparisonRunId,
  countryNumericCodeOrRegion,
  maritalStatus,
  measure,
  indicators
) =>
  countryNumericCodeOrRegion && indicators && indicators.length
    ? createUrl(
        `/api/v1/charts/${runId}/${countryNumericCodeOrRegion}/${maritalStatus}/${measure}`,
        indicators.map(indicator => `indicator=${indicator}`).join("&")
      )
    : undefined;

export const downloadCharts = (
  runId,
  comparisonRunId,
  countryNumericCodeOrRegion,
  maritalStatus,
  measure,
  indicators,
  cancellationToken
) => {
  const url = createChartsDownloadUrl(
    runId,
    comparisonRunId,
    countryNumericCodeOrRegion,
    maritalStatus,
    measure,
    indicators
  );

  return fetch(url, cancellationToken, { responseType: "arraybuffer" });
};

export const calculateTargets = (
  runId,
  countryNumericCodeOrRegion,
  parameters,
  cancellationToken
) =>
  webService
    .post(
      `/api/v1/targets/${runId}/${countryNumericCodeOrRegion}/`,
      parameters,
      {
        headers: createHeaders(),
        cancellationToken
      }
    )
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });

export const calculateProgress = (
  runId,
  countryNumericCodeOrRegion,
  parameters,
  cancellationToken
) =>
  webService
    .post(
      `/api/v1/progress/${runId}/${countryNumericCodeOrRegion}/`,
      parameters,
      {
        headers: createHeaders(),
        cancellationToken
      }
    )
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });

export const fetchRunLog = (runId, cancellationToken) => {
  const url = createRunLogDownloadUrl(runId);

  return fetch(url, cancellationToken);
};

export const saveDatabase = (database, cancellationToken) =>
  webService
    .post(`/api/v1/databases/documents/${database.type}`, database, {
      headers: createHeaders(),
      cancellationToken
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });

export const downloadDatabases = (databaseIds, cancellationToken) => {
  const url = createDatabasesDownloadUrl(databaseIds);

  return fetch(url, cancellationToken, { responseType: "arraybuffer" });
};

export const deleteDatabases = (databaseIds, cancellationToken) => {
  const query = databaseIds.map(databaseId => `id=${databaseId}`).join("&");

  return webService
    .delete(`/api/v1/databases/?${query}`, {
      headers: createHeaders(),
      cancellationToken
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });
};

export const deleteRuns = (runIds, cancellationToken) => {
  const query = runIds.map(runId => `id=${runId}`).join("&");

  return webService
    .delete(`/api/v1/runs/?${query}`, {
      headers: createHeaders(),
      cancellationToken
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });
};
