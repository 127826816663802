import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { contraceptiveMethods, indicators } from "../constants";
import { trimYearToRange } from "../utilities";
import * as api from "../api/api";
import Progress from "../components/Progress/Progress";

const createInitialState = () => {
  return {
    indicatorProgressParameters: {
      indicator: indicators[0].value,
      maritalStatus: "married"
    },
    contraceptiveMethodProgressParameters: {
      maritalStatus: "married",
      contraceptiveMethod: contraceptiveMethods[0].value
    }
  };
};

const validatePeriod = period =>
  period &&
  period.firstYear &&
  period.lastYear &&
  period.firstYear <= period.lastYear;

const createPayload = state => {
  const payload = {};

  if (validatePeriod(state.indicatorProgressParameters.period)) {
    payload.indicatorProgressParameters = state.indicatorProgressParameters;
  }

  if (validatePeriod(state.contraceptiveMethodProgressParameters.period)) {
    payload.contraceptiveMethodProgressParameters =
      state.contraceptiveMethodProgressParameters;
  }

  return payload;
};

class ProgressContainer extends Component {
  constructor(props) {
    super(props);

    this.signal = api.getSignal();

    this.state = createInitialState();
  }

  setStateSafely = (state, callback) => {
    if (!this.unmounting) {
      this.setState(state, callback);
    }
  };

  onSelectIndicatorProgressIndicator = indicator => {
    this.setStateSafely({
      indicatorProgressParameters: {
        ...this.state.indicatorProgressParameters,
        indicator
      }
    });
  };

  onSelectIndicatorProgressMaritalStatus = maritalStatus => {
    this.setStateSafely({
      indicatorProgressParameters: {
        ...this.state.indicatorProgressParameters,
        maritalStatus
      }
    });
  };

  onChangeIndicatorProgressFirstYear = (firstYear, enforceRange) => {
    firstYear = trimYearToRange(firstYear, enforceRange);

    this.setStateSafely({
      indicatorProgressParameters: {
        ...this.state.indicatorProgressParameters,
        period: {
          ...this.state.indicatorProgressParameters.period,
          firstYear
        }
      }
    });
  };

  onChangeIndicatorProgressLastYear = (lastYear, enforceRange) => {
    lastYear = trimYearToRange(lastYear, enforceRange);

    this.setStateSafely({
      indicatorProgressParameters: {
        ...this.state.indicatorProgressParameters,
        period: {
          ...this.state.indicatorProgressParameters.period,
          lastYear
        }
      }
    });
  };

  onSelectContraceptiveMethodProgressMaritalStatus = maritalStatus => {
    this.setStateSafely({
      contraceptiveMethodProgressParameters: {
        ...this.state.contraceptiveMethodProgressParameters,
        maritalStatus
      }
    });
  };

  onSelectContraceptiveMethodProgressContraceptiveMethod = contraceptiveMethod => {
    this.setStateSafely({
      contraceptiveMethodProgressParameters: {
        ...this.state.contraceptiveMethodProgressParameters,
        contraceptiveMethod
      }
    });
  };

  onChangeContraceptiveMethodProgressFirstYear = (firstYear, enforceRange) => {
    firstYear = trimYearToRange(firstYear, enforceRange);

    this.setStateSafely({
      contraceptiveMethodProgressParameters: {
        ...this.state.contraceptiveMethodProgressParameters,
        period: {
          ...this.state.contraceptiveMethodProgressParameters.period,
          firstYear
        }
      }
    });
  };

  onChangeContraceptiveMethodProgressLastYear = (lastYear, enforceRange) => {
    lastYear = trimYearToRange(lastYear, enforceRange);

    this.setStateSafely({
      contraceptiveMethodProgressParameters: {
        ...this.state.contraceptiveMethodProgressParameters,
        period: {
          ...this.state.contraceptiveMethodProgressParameters.period,
          lastYear
        }
      }
    });
  };

  onCalculate = () => {
    this.setStateSafely({
      calculating: true
    });

    const countryNumericCodeOrRegion =
      this.props.countryNumericCode || this.props.region;

    api
      .calculateProgress(
        this.props.runId,
        countryNumericCodeOrRegion,
        createPayload(this.state),
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }
        this.setStateSafely({
          ...response.data
        });
      })
      .finally(() => {
        this.setStateSafely({
          calculating: false
        });
      });
  };

  componentWillUnmount() {
    this.unmounting = true;
    this.signal.cancel();
  }

  render() {
    return (
      <Progress
        indicators={indicators}
        contraceptiveMethods={contraceptiveMethods}
        indicatorProgressParameters={this.state.indicatorProgressParameters}
        indicatorProgress={this.state.indicatorProgress}
        contraceptiveMethodProgressParameters={
          this.state.contraceptiveMethodProgressParameters
        }
        contraceptiveMethodProgress={this.state.contraceptiveMethodProgress}
        valid={
          (!!this.props.countryNumericCode || !!this.props.region) &&
          Object.keys(createPayload(this.state)).length > 0
        }
        calculating={this.state.calculating}
        onSelectIndicatorProgressIndicator={
          this.onSelectIndicatorProgressIndicator
        }
        onSelectIndicatorProgressMaritalStatus={
          this.onSelectIndicatorProgressMaritalStatus
        }
        onChangeIndicatorProgressFirstYear={
          this.onChangeIndicatorProgressFirstYear
        }
        onChangeIndicatorProgressLastYear={
          this.onChangeIndicatorProgressLastYear
        }
        onSelectContraceptiveMethodProgressMaritalStatus={
          this.onSelectContraceptiveMethodProgressMaritalStatus
        }
        onSelectContraceptiveMethodProgressContraceptiveMethod={
          this.onSelectContraceptiveMethodProgressContraceptiveMethod
        }
        onChangeContraceptiveMethodProgressFirstYear={
          this.onChangeContraceptiveMethodProgressFirstYear
        }
        onChangeContraceptiveMethodProgressLastYear={
          this.onChangeContraceptiveMethodProgressLastYear
        }
        onCalculate={this.onCalculate}
      />
    );
  }
}

ProgressContainer.propTypes = {
  runId: PropTypes.string,
  countryNumericCode: PropTypes.number,
  region: PropTypes.string
};

export default ProgressContainer;
