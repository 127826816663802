import React, { Component } from "react";
import * as queryString from "query-string";
import {
  defaultFirstYear,
  indicators,
  maximumYear,
  minimumYear
} from "../constants";
import {
  createSlug,
  getDefaultLastYear,
  toggleSetExistence
} from "../utilities";
import {
  convertMaritalStatusToIsInUnion,
  filterPopulationData,
  filterResults,
  filterSurveyData,
  getPeriod,
  normalizeChartSets,
  normalizeDatabases,
  normalizeEmuData,
  normalizePopulationData,
  normalizeResults,
  normalizeRuns,
  normalizeSurveyData
} from "../data";
import * as api from "../api/api";
import fileDownload from "js-file-download";
import { withErrorBoundary } from "../errors";
import { withTranslation } from "react-i18next";
import ViewRun from "../components/ViewRun/ViewRun";

const createInitialState = () => ({
  period: {
    firstYear: defaultFirstYear,
    lastYear: getDefaultLastYear()
  },
  selectedResultsMeasure: "percentage",
  selectedResultsIndicator: indicators[0].value,
  selectedResultsMaritalStatus: "married",
  selectedExportedResultsMeasures: new Set(),
  selectedExportedResultsIndicators: new Set(),
  selectedChartsMeasure: "percentage",
  selectedChartsMaritalStatus: "married",
  selectedChartsIndicators: new Set(
    indicators
      .filter(indicator => indicator.chart)
      .map(indicator => indicator.value)
  ),
  showSurveyLegend: true,
  chartSets: [
    {
      value: "complete",
      label: "Complete"
    }
  ],
  selectedExportedChartSet: "complete",
  selectedExportedChartsMeasure: "percentage",
  selectedExportedChartsMaritalStatus: "married"
});

class ViewRunContainer extends Component {
  constructor(props) {
    super(props);

    this.signal = api.getSignal();

    this.state = createInitialState();
  }

  setStateSafely = (state, callback) => {
    if (!this.unmounting) {
      this.setState(state, callback);
    }
  };

  getCountries = runId => {
    if (!runId) {
      return {};
    }

    const run = this.state.runs[runId];

    const divisions = this.state.databases[run.surveyDatabaseId].divisions;

    return run.countryNumericCodes.reduce((result, countryNumericCode) => {
      if (
        divisions[countryNumericCode] &&
        divisions[countryNumericCode].isCountry
      ) {
        result[countryNumericCode] = {
          numericCode: countryNumericCode,
          ...divisions[countryNumericCode]
        };
      }

      return result;
    }, {});
  };

  getRegions = runId => {
    if (!runId) {
      return [];
    }

    const run = this.state.runs[runId];

    return run.regionCodes || [];
  };

  selectDefaultCountryOrRegion = runId => {
    const countryNumericCodes = Object.keys(this.getCountries(runId));
    const regions = this.getRegions(runId);

    if (countryNumericCodes.length + regions.length === 1) {
      if (countryNumericCodes.length) {
        this.setStateSafely({
          selectedCountryNumericCode: parseInt(countryNumericCodes[0], 10)
        });
      } else {
        this.setStateSafely({
          selectedRegion: regions[0]
        });
      }
    }
  };

  getSelectedCountryNumericCodeOrRegion = () =>
    this.state.selectedRegion || this.state.selectedCountryNumericCode;

  createRunTitle = () => {
    let countryAlphaCodeOrRegion;

    if (this.state.selectedRegion) {
      countryAlphaCodeOrRegion = this.state.selectedRegion;
    } else if (this.state.selectedCountryNumericCode) {
      const selectedRun = this.state.runs[this.state.selectedRunId];

      countryAlphaCodeOrRegion = this.state.databases[
        selectedRun.surveyDatabaseId
      ].divisions[this.state.selectedCountryNumericCode].alphaCode;
    }

    if (countryAlphaCodeOrRegion) {
      const selectedRun = this.state.runs[this.state.selectedRunId];

      const { firstYear, lastYear } = this.state.period;

      return this.props.t(
        "Run {{name}} for {{countryAlphaCodeOrRegion}} during {{firstYear}}-{{lastYear}}",
        {
          name: selectedRun.name,
          countryAlphaCodeOrRegion,
          firstYear,
          lastYear
        }
      );
    }
  };

  viewRun = () => {
    this.setStateSafely({
      loadingRun: true
    });

    const run = this.state.runs[this.state.selectedRunId];

    const countryNumericCodeOrRegion =
      this.state.selectedRegion || this.state.selectedCountryNumericCode;

    // noinspection JSCheckFunctionSignatures
    Promise.all([
      api.fetchSurveyDatabase(
        run.surveyDatabaseId,
        countryNumericCodeOrRegion,
        this.state.selectedRunId,
        this.signal.token
      ),
      api.fetchPopulationDatabase(
        run.populationDatabaseId,
        countryNumericCodeOrRegion,
        this.signal.token
      ),
      api.fetchResults(
        this.state.selectedRunId,
        undefined,
        countryNumericCodeOrRegion,
        this.signal.token
      ),
      run.emuDatabaseId
        ? api.fetchEmuDatabase(
            run.emuDatabaseId,
            countryNumericCodeOrRegion,
            this.signal.token
          )
        : new Promise(resolve =>
            resolve({
              data: []
            })
          )
    ])
      .then(response => {
        console.log("Fetched survey");

        if (!response || response.some(item => !item)) {
          return;
        }

        const [surveyData, populationData, results, emuData] = response;

        const period = getPeriod(results.data.proportions);

        this.setStateSafely({
          period,
          surveyData: normalizeSurveyData(surveyData.data),
          populationData: normalizePopulationData(populationData.data),
          emuData: normalizeEmuData(emuData.data),
          results: {
            proportions: normalizeResults(results.data.proportions),
            populations: normalizeResults(results.data.populations)
          }
        });
      })
      .finally(() => {
        this.setStateSafely({
          loadingRun: false
        });
      });
  };

  getMeasureResultsForPeriod = measure => {
    if (!this.state.results) {
      return;
    }

    const measureKey = measure === "percentage" ? "proportions" : "populations";

    return filterResults(
      this.state.results[measureKey],
      this.state.period.firstYear,
      this.state.period.lastYear
    );
  };

  getTableResults = () => {
    const results = this.getMeasureResultsForPeriod(
      this.state.selectedResultsMeasure
    );

    if (!results) {
      return results;
    }

    const isInUnion = convertMaritalStatusToIsInUnion(
      this.state.selectedResultsMaritalStatus
    );

    return results[this.state.selectedResultsIndicator].filter(
      datum => datum.isInUnion === isInUnion
    );
  };

  getChartSurveyData = surveyData => {
    if (!surveyData) {
      return;
    }

    const isInUnion = convertMaritalStatusToIsInUnion(
      this.state.selectedChartsMaritalStatus
    );

    return surveyData.filter(datum => datum.isInUnion === isInUnion);
  };

  getChartResults = () => {
    const measureResultsForPeriod = this.getMeasureResultsForPeriod(
      this.state.selectedChartsMeasure
    );

    if (!measureResultsForPeriod) {
      return;
    }

    const isInUnion = convertMaritalStatusToIsInUnion(
      this.state.selectedChartsMaritalStatus
    );

    const results = Object.keys(measureResultsForPeriod).reduce(
      (result, indicator) => {
        if (this.state.selectedChartsIndicators.has(indicator)) {
          result[indicator] = measureResultsForPeriod[indicator].filter(
            datum => datum.isInUnion === isInUnion
          );
        }

        return result;
      },
      {}
    );

    if (
      !Object.keys(results).length ||
      !results[Object.keys(results)[0]].length
    ) {
      return;
    }

    return results;
  };

  onSelectRun = runId => {
    this.setStateSafely({
      selectedRunId: runId,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined
    });

    this.selectDefaultCountryOrRegion(runId);
  };

  onSelectCountry = countryNumericCode => {
    this.setStateSafely({
      selectedCountryNumericCode: countryNumericCode,
      selectedRegion: undefined
    });
  };

  onSelectRegion = region => {
    this.setStateSafely({
      selectedRegion: region
    });
  };

  onChangePeriod = period => {
    this.setStateSafely({
      period
    });
  };

  onViewRun = () => {
    this.viewRun();
  };

  onChangeResultsMeasure = measure => {
    this.setStateSafely({
      selectedResultsMeasure: measure,
      selectedResultsIndicator:
        measure === "percentage" ||
        this.state.selectedResultsIndicator !== "ratioModernAny"
          ? this.state.selectedResultsIndicator
          : indicators[0].value
    });
  };

  onChangeResultsIndicator = indicator => {
    this.setStateSafely({
      selectedResultsIndicator: indicator
    });
  };

  onChangeResultsMaritalStatus = martialStatus => {
    this.setStateSafely({
      selectedResultsMaritalStatus: martialStatus
    });
  };

  onToggleExportedResultsMeasure = measure => {
    const selectedExportedResultsMeasures = new Set(
      this.state.selectedExportedResultsMeasures
    );

    toggleSetExistence(selectedExportedResultsMeasures, measure);

    this.setStateSafely({
      selectedExportedResultsMeasures
    });
  };

  onToggleExportedResultsIndicator = indicator => {
    const selectedExportedResultsIndicators = new Set(
      this.state.selectedExportedResultsIndicators
    );

    toggleSetExistence(selectedExportedResultsIndicators, indicator);

    this.setStateSafely({
      selectedExportedResultsIndicators
    });
  };

  onDownloadSelectedResults = () => {
    api
      .downloadResults(
        this.state.selectedRunId,
        undefined,
        this.getSelectedCountryNumericCodeOrRegion(),
        Array.from(this.state.selectedExportedResultsMeasures),
        Array.from(this.state.selectedExportedResultsIndicators),
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }

        const run = this.state.runs[this.state.selectedRunId];

        if (run) {
          fileDownload(response.data, createSlug(run.name, "results.csv"));
        }
      });
  };

  onDownloadAllResults = () => {
    api
      .downloadResults(
        this.state.selectedRunId,
        undefined,
        this.getSelectedCountryNumericCodeOrRegion(),
        ["percentage", "count"],
        indicators.map(indicator => indicator.value),
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }

        const run = this.state.runs[this.state.selectedRunId];

        if (run) {
          fileDownload(response.data, createSlug(run.name, "all_results.csv"));
        }
      });
  };

  onDownloadAllData = () => {
    api
      .downloadData(
        this.state.selectedRunId,
        this.getSelectedCountryNumericCodeOrRegion(),
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }

        const run = this.state.runs[this.state.selectedRunId];

        if (run) {
          fileDownload(response.data, createSlug(run.name, "all_data.zip"));
        }
      });
  };

  onChangeChartsMeasure = measure => {
    this.setStateSafely({
      selectedChartsMeasure: measure
    });
  };

  onChangeChartsMaritalStatus = martialStatus => {
    this.setStateSafely({
      selectedChartsMaritalStatus: martialStatus
    });
  };

  onToggleChart = chart => {
    const selectedChartsIndicators = new Set(
      this.state.selectedChartsIndicators
    );

    toggleSetExistence(selectedChartsIndicators, chart);

    this.setStateSafely({
      selectedChartsIndicators
    });
  };

  onToggleSurveyLegend = () => {
    this.setStateSafely({
      showSurveyLegend: !this.state.showSurveyLegend
    });
  };

  onChangeExportedChartSet = chartSet => {
    this.setStateSafely({
      selectedExportedChartSet: chartSet
    });
  };

  onChangeExportedChartsMeasure = measure => {
    this.setStateSafely({
      selectedExportedChartsMeasure: measure
    });
  };

  onChangeExportedChartsMaritalStatus = maritalStatus => {
    this.setStateSafely({
      selectedExportedChartsMaritalStatus: maritalStatus
    });
  };

  onDownloadChartSet = () => {
    api
      .downloadChartSet(
        this.state.selectedRunId,
        undefined,
        this.getSelectedCountryNumericCodeOrRegion(),
        this.state.selectedExportedChartSet,
        this.state.selectedExportedChartsMaritalStatus,
        this.state.selectedExportedChartsMeasure,
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }

        const run = this.state.runs[this.state.selectedRunId];

        if (run) {
          const prefix = `${run.name}_${this.state.selectedExportedChartSet}`;

          fileDownload(response.data, createSlug(prefix, "charts.pdf"));
        }
      });
  };

  onDownloadSelectedCharts = () => {
    api
      .downloadCharts(
        this.state.selectedRunId,
        undefined,
        this.getSelectedCountryNumericCodeOrRegion(),
        this.state.selectedExportedChartsMaritalStatus,
        this.state.selectedExportedChartsMeasure,
        Array.from(this.state.selectedChartsIndicators),
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }

        const run = this.state.runs[this.state.selectedRunId];

        if (run) {
          fileDownload(response.data, createSlug(run.name, "charts.pdf"));
        }
      });
  };

  componentDidMount() {
    this.setStateSafely({
      loadingData: true
    });

    // noinspection JSCheckFunctionSignatures
    Promise.all([
      api.fetchDatabases(this.signal.token),
      api.fetchRuns(false, false, this.signal.token),
      api.fetchChartSets(this.signal.token)
    ])
      .then(response => {
        if (!response || response.some(item => !item)) {
          return;
        }

        const databases = normalizeDatabases(response[0].data);
        const runs = normalizeRuns(response[1].data);
        const chartSets = normalizeChartSets(response[2].data);

        let { run: selectedRunId } = queryString.parse(
          this.props.location.search
        );

        if (selectedRunId && !runs[selectedRunId]) {
          console.log(`Run ${selectedRunId} does not exist`);

          selectedRunId = undefined;
        }

        const selectedRegion =
          (selectedRunId &&
            runs[selectedRunId] &&
            runs[selectedRunId].regionCodes.length &&
            runs[selectedRunId].regionCodes[0]) ||
          undefined;

        const selectedCountryNumericCode =
          (!selectedRegion &&
            selectedRunId &&
            runs[selectedRunId] &&
            runs[selectedRunId].countryNumericCodes.length &&
            runs[selectedRunId].countryNumericCodes[0]) ||
          undefined;

        this.setStateSafely(
          {
            databases: databases,
            runs: runs,
            selectedRunId,
            selectedCountryNumericCode,
            selectedRegion,
            chartSets
          },
          selectedRunId
            ? () => {
                this.viewRun();
              }
            : undefined
        );
      })
      .finally(() => {
        this.setStateSafely({
          loadingData: false
        });
      });
  }

  componentWillUnmount() {
    this.unmounting = true;
    this.signal.cancel();
  }

  render() {
    const { firstYear, lastYear } = {
      firstYear: minimumYear,
      lastYear: maximumYear
    };

    const surveyData = filterSurveyData(
      this.state.surveyData,
      firstYear,
      lastYear
    );

    const populationData = filterPopulationData(
      this.state.populationData,
      firstYear,
      lastYear
    );

    return (
      <ViewRun
        match={this.props.match}
        loadingData={this.state.loadingData}
        runs={this.state.runs}
        selectedRunId={this.state.selectedRunId}
        countries={this.getCountries(this.state.selectedRunId)}
        selectedCountryNumericCode={this.state.selectedCountryNumericCode}
        regions={this.getRegions(this.state.selectedRunId)}
        selectedRegion={this.state.selectedRegion}
        period={this.state.period}
        loadingRun={this.state.loadingRun}
        runTitle={this.createRunTitle()}
        surveyData={surveyData}
        populationData={populationData}
        emuData={this.state.emuData}
        selectedResultsMeasure={this.state.selectedResultsMeasure}
        selectedResultsIndicator={this.state.selectedResultsIndicator}
        selectedResultsMaritalStatus={this.state.selectedResultsMaritalStatus}
        tableResults={this.getTableResults()}
        selectedExportedResultsMeasures={Array.from(
          this.state.selectedExportedResultsMeasures
        )}
        selectedExportedResultsIndicators={Array.from(
          this.state.selectedExportedResultsIndicators
        )}
        disableSelectedResultsDownload={
          !api.createResultsDownloadUrl(
            this.state.selectedRunId,
            undefined,
            this.getSelectedCountryNumericCodeOrRegion(),
            Array.from(this.state.selectedExportedResultsMeasures),
            Array.from(this.state.selectedExportedResultsIndicators)
          )
        }
        disableAllResultsDownload={
          !this.getSelectedCountryNumericCodeOrRegion()
        }
        disableAllDataDownload={!this.getSelectedCountryNumericCodeOrRegion()}
        selectedChartsMeasure={this.state.selectedChartsMeasure}
        selectedChartsMaritalStatus={this.state.selectedChartsMaritalStatus}
        selectedChartsIndicators={Array.from(
          this.state.selectedChartsIndicators
        )}
        showSurveyLegend={this.state.showSurveyLegend}
        chartResults={this.getChartResults()}
        chartSurveyData={this.getChartSurveyData(surveyData)}
        chartEmuData={
          this.state.selectedChartsMaritalStatus === "married"
            ? this.state.emuData
            : undefined
        }
        chartSets={this.state.chartSets}
        selectedExportedChartSet={this.state.selectedExportedChartSet}
        selectedExportedChartsMeasure={this.state.selectedExportedChartsMeasure}
        selectedExportedChartsMaritalStatus={
          this.state.selectedExportedChartsMaritalStatus
        }
        disableChartSetDownload={
          !api.createChartSetDownloadUrl(
            this.state.selectedRunId,
            undefined,
            this.getSelectedCountryNumericCodeOrRegion(),
            this.state.selectedExportedChartSet,
            this.state.selectedExportedChartsMaritalStatus,
            this.state.selectedExportedChartsMeasure
          )
        }
        disableSelectedChartsDownload={
          !api.createChartsDownloadUrl(
            this.state.selectedRunId,
            undefined,
            this.getSelectedCountryNumericCodeOrRegion(),
            this.state.selectedExportedChartsMaritalStatus,
            this.state.selectedExportedChartsMeasure,
            Array.from(this.state.selectedChartsIndicators)
          )
        }
        onSelectRun={this.onSelectRun}
        onSelectCountry={this.onSelectCountry}
        onSelectRegion={this.onSelectRegion}
        onChangePeriod={this.onChangePeriod}
        onViewRun={this.onViewRun}
        onChangeResultsMeasure={this.onChangeResultsMeasure}
        onChangeResultsIndicator={this.onChangeResultsIndicator}
        onChangeResultsMaritalStatus={this.onChangeResultsMaritalStatus}
        onToggleExportedResultsMeasure={this.onToggleExportedResultsMeasure}
        onToggleExportedResultsIndicator={this.onToggleExportedResultsIndicator}
        onDownloadSelectedResults={this.onDownloadSelectedResults}
        onDownloadAllResults={this.onDownloadAllResults}
        onDownloadAllData={this.onDownloadAllData}
        onChangeChartsMeasure={this.onChangeChartsMeasure}
        onChangeChartsMaritalStatus={this.onChangeChartsMaritalStatus}
        onToggleChart={this.onToggleChart}
        onToggleSurveyLegend={this.onToggleSurveyLegend}
        onChangeExportedChartSet={this.onChangeExportedChartSet}
        onChangeExportedChartsMeasure={this.onChangeExportedChartsMeasure}
        onChangeExportedChartsMaritalStatus={
          this.onChangeExportedChartsMaritalStatus
        }
        onDownloadChartSet={this.onDownloadChartSet}
        onDownloadSelectedCharts={this.onDownloadSelectedCharts}
      />
    );
  }
}

export default withTranslation()(withErrorBoundary(ViewRunContainer));
