import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { databasePropType, databaseTypePropType } from "../../propTypes";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import DatabaseUploader from "./StartRunDatabaseUploader";

const styles = {
  dataTypeSpecificComponents: {
    margin: "10px 0"
  },
  inputLabel: {
    fontSize: "75%"
  },
  radio: {
    padding: "2px 12px"
  },
  select: {
    marginTop: 10
  }
};

class StartRunDatabasePicker extends Component {
  getDatabaseTypeSpecificComponents = () => {
    switch (this.props.databaseType) {
      case "default":
        const defaultDatabases = this.props.defaultDatabases.map(
          ({ id, name }) => ({
            value: id,
            label: name
          })
        );

        return (
          <div>
            <InputLabel className={this.props.classes.inputLabel}>
              {this.props.t("Choose default database")}
            </InputLabel>

            <Select
              placeholder={this.props.t("Select database...")}
              options={defaultDatabases}
              value={defaultDatabases.filter(
                ({ value }) => value === this.props.selectedDatabaseId
              )}
              noOptionsMessage={() =>
                this.props.t("No default databases available")
              }
              className={this.props.classes.select}
              onChange={({ value }) => this.props.onSelectDatabase(value)}
            />
          </div>
        );

      case "custom":
        const customDatabases = this.props.customDatabases.map(
          ({ id, name }) => ({
            value: id,
            label: name
          })
        );

        return (
          <div>
            <InputLabel className={this.props.classes.inputLabel}>
              Choose custom database
            </InputLabel>

            <Select
              placeholder={this.props.t("Select database...")}
              options={customDatabases}
              value={customDatabases.filter(
                ({ value }) => value === this.props.selectedDatabaseId
              )}
              noOptionsMessage={() =>
                this.props.t("No custom databases available")
              }
              className={this.props.classes.select}
              onChange={({ value }) => this.props.onSelectDatabase(value)}
            />
          </div>
        );

      case "external":
        return (
          <DatabaseUploader
            databaseName={this.props.uploadedDatabaseName}
            uploadingDatabase={this.props.uploadingDatabase}
            databaseUploadProgress={this.props.databaseUploadProgress}
            onSelectDatabase={this.props.onSelectDatabaseFile}
            onUpload={this.props.onUploadDatabase}
            onCancelUpload={this.props.onCancelDatabaseUpload}
          />
        );

      default:
        console.error(`${this.props.databaseType} is an unknown database type`);

        break;
    }
  };

  render() {
    return (
      <div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Database type"
            value={this.props.databaseType}
            onChange={(_, value) => this.props.onChangeDatabaseType(value)}
          >
            {this.props.variant !== "emu" && (
              <FormControlLabel
                value="default"
                control={<Radio className={this.props.classes.radio} />}
                label={this.props.t("Default")}
              />
            )}
            <FormControlLabel
              value="custom"
              control={<Radio className={this.props.classes.radio} />}
              label={this.props.t("Custom")}
            />
            <FormControlLabel
              value="external"
              control={<Radio className={this.props.classes.radio} />}
              label={this.props.t("External")}
            />
          </RadioGroup>
        </FormControl>

        <div className={this.props.classes.dataTypeSpecificComponents}>
          {this.getDatabaseTypeSpecificComponents()}
        </div>
      </div>
    );
  }
}

StartRunDatabasePicker.propTypes = {
  variant: PropTypes.oneOf(["default", "emu"]),
  databaseType: databaseTypePropType,
  defaultDatabases: PropTypes.arrayOf(databasePropType),
  customDatabases: PropTypes.arrayOf(databasePropType).isRequired,
  selectedDatabaseId: PropTypes.string,
  uploadedDatabaseName: PropTypes.string,
  uploadingDatabase: PropTypes.bool,
  databaseUploadProgress: PropTypes.number,
  onChangeDatabaseType: PropTypes.func.isRequired,
  onSelectDatabase: PropTypes.func.isRequired,
  onSelectDatabaseFile: PropTypes.func.isRequired,
  onUploadDatabase: PropTypes.func.isRequired,
  onCancelDatabaseUpload: PropTypes.func.isRequired
};

StartRunDatabasePicker.defaultProps = {
  variant: "default",
  databaseType: "default",
  defaultDatabases: [],
  uploadingDatabase: false
};

export default withTranslation()(withStyles(styles)(StartRunDatabasePicker));
