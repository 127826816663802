import React from "react";
import * as PropTypes from "prop-types";
import { measurePropType, indicatorResultPropType } from "../../propTypes";
import { indicators } from "../../constants";
import { withStyles } from "@material-ui/core/styles";
import IndicatorSelector from "./ResultsIndicatorSelector";
import MaritalStatusSelector from "../MaritalStatusSelector";
import MeasureSelector from "../MeasureSelector";
import ResultsExporter from "./ResultsExporter";
import ResultsTable from "./ResultsTable";

const styles = {
  container: {
    marginTop: 20
  },
  gridSelectors: {
    display: "flex"
  },
  leftSelector: {
    marginRight: 30
  },
  table: {
    marginTop: 10
  }
};

const Results = props => (
  <div className={props.classes.container}>
    <div className={props.classes.gridSelectors}>
      <MeasureSelector
        value={props.selectedMeasure}
        className={props.classes.leftSelector}
        onChange={props.onChangeMeasure}
      />
      <IndicatorSelector
        indicators={indicators.filter(
          indicator =>
            props.selectedMeasure === "percentage" ||
            indicator.value !== "ratioModernAny"
        )}
        value={props.selectedIndicator}
        className={props.classes.leftSelector}
        onChange={props.onChangeIndicator}
      />
      <MaritalStatusSelector
        value={props.selectedMaritalStatus}
        onChange={props.onChangeMaritalStatus}
      />
    </div>

    <ResultsTable
      measure={props.selectedMeasure}
      results={props.results}
      comparison={props.comparison}
      className={props.classes.table}
    />

    <div>
      <ResultsExporter
        comparison={props.comparison}
        selectedMeasures={props.selectedExportedMeasures}
        indicators={indicators}
        selectedIndicators={props.selectedExportedIndicators}
        disableSelectedResultsDownload={props.disableSelectedResultsDownload}
        disableAllResultsDownload={props.disableAllResultsDownload}
        disableAllDataDownload={props.disableAllDataDownload}
        onToggleMeasure={props.onToggleExportedMeasure}
        onToggleIndicator={props.onToggleExportedIndicator}
        onDownloadSelectedResults={props.onDownloadSelectedResults}
        onDownloadAllResults={props.onDownloadAllResults}
        onDownloadAllData={props.onDownloadAllData}
      />
    </div>
  </div>
);

Results.propTypes = {
  comparison: PropTypes.bool,
  selectedMeasure: measurePropType.isRequired,
  selectedIndicator: PropTypes.string.isRequired,
  selectedMaritalStatus: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(indicatorResultPropType),
  selectedExportedMeasures: PropTypes.arrayOf(measurePropType),
  selectedExportedIndicators: PropTypes.arrayOf(PropTypes.string),
  disableSelectedResultsDownload: PropTypes.bool.isRequired,
  disableAllResultsDownload: PropTypes.bool.isRequired,
  disableAllDataDownload: PropTypes.bool,
  onChangeMeasure: PropTypes.func.isRequired,
  onChangeIndicator: PropTypes.func.isRequired,
  onChangeMaritalStatus: PropTypes.func.isRequired,
  onToggleExportedMeasure: PropTypes.func.isRequired,
  onToggleExportedIndicator: PropTypes.func.isRequired,
  onDownloadSelectedResults: PropTypes.func.isRequired,
  onDownloadAllResults: PropTypes.func.isRequired,
  onDownloadAllData: PropTypes.func
};

Results.defaultProps = {
  comparison: false,
  results: [],
  selectedExportedMeasures: [],
  selectedExportedIndicators: [],
  disableAllDataDownload: true
};

export default withStyles(styles)(Results);
