import React from "react";
import * as PropTypes from "prop-types";
import { indicatorResultPropType, measurePropType } from "../../propTypes";
import {
  formatLogicalCell,
  formatNumericCell,
  formatPercentageCell
} from "../../utilities";
import classNames from "classnames";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";

const getFormatter = measure =>
  measure === "percentage"
    ? value => formatPercentageCell(value, 1)
    : value => formatNumericCell(value / 1000, 0);

const getColumnDefinitionsForSingleRun = estimateFormatter => [
  {
    headerName: "In union?",
    field: "isInUnion",
    valueFormatter: params => formatLogicalCell(params.value)
  },
  {
    headerName: "Year",
    field: "year",
    type: "numericColumn",
    filter: "agNumberColumnFilter"
  },
  {
    headerName: "2.5%",
    field: "percentile2pt5",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: params => estimateFormatter(params.value)
  },
  {
    headerName: "10%",
    field: "percentile10",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: params => estimateFormatter(params.value)
  },
  {
    headerName: "Median",
    field: "median",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: params => estimateFormatter(params.value)
  },
  {
    headerName: "90%",
    field: "percentile90",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: params => estimateFormatter(params.value)
  },
  {
    headerName: "97.5%",
    field: "percentile97pt5",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: params => estimateFormatter(params.value)
  }
];

const getColumnDefinitionsRunComparison = estimateFormatter => [
  {
    headerName: "In union?",
    field: "isInUnion",
    valueFormatter: params => formatLogicalCell(params.value)
  },
  {
    headerName: "Year",
    field: "year",
    type: "numericColumn",
    filter: "agNumberColumnFilter"
  },
  {
    headerName: "Mean #1",
    field: "mean",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: params => estimateFormatter(params.value)
  },
  {
    headerName: "Mean #2",
    field: "comparisonMean",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: params => estimateFormatter(params.value)
  },
  {
    headerName: "Median #1",
    field: "median",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: params => estimateFormatter(params.value)
  },
  {
    headerName: "Median #2",
    field: "comparisonMedian",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: params => estimateFormatter(params.value)
  }
];

const ResultsTable = ({ t, ...rest }) => {
  const columnDefinitions = rest.comparison
    ? getColumnDefinitionsRunComparison(getFormatter(rest.measure))
    : getColumnDefinitionsForSingleRun(getFormatter(rest.measure));

  return (
    <div
      key={rest.measure}
      className={classNames("ag-theme-material", rest.className)}
    >
      <AgGridReact
        columnDefs={columnDefinitions.map(datum => ({
          ...datum,
          headerName: t(datum.headerName)
        }))}
        domLayout="autoHeight"
        defaultColDef={{
          sortable: true,
          filter: true
        }}
        pagination={true}
        paginationPageSize={10}
        suppressColumnVirtualisation={true}
        suppressCellSelection={true}
        overlayNoRowsTemplate="<span />"
        localeTextFunc={translateGridText}
        rowData={rest.results.map(datum => ({
          ...datum,
          year: datum.year + 0.5
        }))}
        onGridReady={params => {
          params.columnApi.autoSizeColumns(
            params.columnApi.getAllColumns().map(column => column.colId)
          );
        }}
      />
    </div>
  );
};

ResultsTable.propTypes = {
  measure: measurePropType.isRequired,
  results: PropTypes.arrayOf(indicatorResultPropType),
  comparison: PropTypes.bool,
  className: PropTypes.string
};

ResultsTable.defaultProps = {
  results: [],
  comparison: false
};

export default withTranslation()(ResultsTable);
