import React from "react";
import * as PropTypes from "prop-types";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import About from "./About";
import CompareRunsContainer from "../containers/CompareRunsContainer";
import LanguageChooser from "./LanguageChooser/LanguageChooser";
import ManageDataContainer from "../containers/ManageDataContainer";
import PrepareDataContainer from "../containers/PrepareDataContainer";
import RunHistoryContainer from "../containers/RunHistoryContainer";
import StartRunContainer from "../containers/StartRunContainer";
import ViewRunContainer from "../containers/ViewRunContainer";

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  brand: {
    marginRight: 30,
    whiteSpace: "nowrap",
  },
  content: {
    margin: 20,
  },
  languages: {
    marginRight: 20,
  },
  signOut: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  signOutLabel: {
    whiteSpace: "nowrap",
  },
});

const getMainSectionPath = (path) => `/${path.split("/")[1]}`;

const App = (props) => {
  const mainSectionPath = getMainSectionPath(props.location.pathname);

  const { t } = useTranslation();

  return (
    <div>
      <AppBar position="sticky" className={props.classes.appBar}>
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            className={props.classes.brand}
          >
            {t("APP_TITLE")}
          </Typography>

          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={mainSectionPath}
          >
            <Tab label={t("About")} value="/" component={Link} to="/" />
            <Tab
              label={t("Start run|APP", {
                defaultValue: "Start run",
              })}
              value="/startrun"
              component={Link}
              to="/startrun"
            />
            <Tab
              label={t("View run")}
              value="/viewrun"
              component={Link}
              to="/viewrun"
            />
            <Tab
              label={t("Compare runs")}
              value="/compareruns"
              component={Link}
              to="/compareruns"
            />
            <Tab
              label={t("Run history")}
              value="/history"
              component={Link}
              to="/history"
            />
            <Tab
              label={t("Prepare data")}
              value="/preparedata"
              component={Link}
              to="/preparedata"
            />
            <Tab
              label={t("Manage data")}
              value="/managedata"
              component={Link}
              to="/managedata"
            />
          </Tabs>

          <div className={props.classes.languages}>
            <LanguageChooser onChangeLanguage={props.onChangeLanguage} />
          </div>

          <div className={props.classes.signIn}>
            <Button
              color="inherit"
              classes={{
                label: props.classes.signOutLabel,
              }}
              onClick={props.onLogout}
            >
              {t("Sign out")}
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <div className={props.classes.content}>
        <Switch>
          <Route exact path="/" component={About} />
          <Route path="/startrun" component={StartRunContainer} />
          <Route path="/viewrun" component={ViewRunContainer} />
          <Route path="/compareruns" component={CompareRunsContainer} />
          <Route path="/history" component={RunHistoryContainer} />
          <Route path="/preparedata" component={PrepareDataContainer} />
          <Route path="/managedata" component={ManageDataContainer} />
          />
        </Switch>
      </div>
    </div>
  );
};

App.propTypes = {
  onChangeLanguage: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(App));
